<script setup lang="ts">
import dayjs from 'dayjs';

import { CardInfo } from '~/types/hardCurrencyPurchase';
import { PREMIUM_STORE_STATE } from '~/services/premiumStoreService';

type CardItem = CardInfo & {  endDateSale?: string; type?: 'daily' | 'flash' | 'default'; endTimestamp?: number };

const props = withDefaults(defineProps<{
    title: string;
    bits: number;
    items?: CardInfo[];
    endSaleDate?: string;
    endTimestamp?: number;
    storeState: PREMIUM_STORE_STATE;
    freePackState: 'purchasing' | 'success' | 'error' | undefined;
    availableFreePacks: string[];
}>(), {
    items: () => [],
    endSaleDate: undefined,
    endTimestamp: undefined,
});

const emit = defineEmits<{
    (e: 'buy', id: string): void
    (e: 'close'): void
    (e: 'done'): void
}>();

const selectedItemId = ref<string | undefined>(undefined);

const selectedItem = computed(() => cardItems.value.find((item) => item.id === selectedItemId.value));

const handleClosePopup = () => {
    selectedItemId.value = undefined;
    emit('close');
};

const handleDoneResultPopup = () => {
    selectedItemId.value = undefined;
    emit('done');
};

const isVisiblePurchaseResult = computed(() =>
    props.storeState === PREMIUM_STORE_STATE.PURCHASE_SUCCESS
    || props.storeState === PREMIUM_STORE_STATE.PURCHASE_ERROR
    || props.freePackState === 'success'
    || props.freePackState === 'error',
);

const isVisiblePurchaseWindow = computed(() =>
    !isVisiblePurchaseResult.value
    && props.freePackState !== 'purchasing'
    && props.storeState !== PREMIUM_STORE_STATE.PURCHASING,
);

const getRecalculatedItems = () => {
    return props.items.map((item) => {
        const flashEndDate = item.flashSaleSetting?.endDate && item.flashSaleSetting.enabled ?
            item.flashSaleSetting.endDate : undefined;

        const dailyEndDate = item.dailySale ? dayjs().utc().endOf('day') : undefined;
        const endDateSale = flashEndDate || dailyEndDate;

        let type = item.flashSaleSetting?.enabled ? 'flash' : item.dailySale ? 'daily' : 'default';

        return {
            ...item,
            endTimestamp: endDateSale ? dayjs(endDateSale).unix() * 1000 : undefined,
            type,
        };
    }).sort((a, b) => {
        const order = ['flash', 'daily', 'default'];

        return order.indexOf(a.type) - order.indexOf(b.type);
    }) as CardItem[];
};

const cardItems = computed(() => getRecalculatedItems());
const isHaveDiscount = computed(() => cardItems.value.some((item) => item.type && item.type !== 'default'));
const headerEndTimestamp = computed(() => selectedItem.value ? selectedItem.value?.endTimestamp : props.endTimestamp);

const currentTimestamp = useTimestamp({ interval: 1000 });
</script>

<template>
    <div class="purchase-popup">
        <div
            v-if="isVisiblePurchaseWindow || isVisiblePurchaseResult"
            class="purchase-popup__inner"
            :class="[{ 'purchase-popup__inner--secondary': isVisiblePurchaseResult }] "
        >
            <HardCurrencyPurchaseHeader
                v-if="isVisiblePurchaseWindow"
                :type="isHaveDiscount ? 'small' : 'big'"
                :bits="bits"
                :end-timestamp="headerEndTimestamp"
                :current-timestamp="currentTimestamp"
                @close="handleClosePopup"
            >
                {{ title }}
            </HardCurrencyPurchaseHeader>

            <HardCurrencyPurchaseItems
                v-if="isVisiblePurchaseWindow"
                v-show="!selectedItem"
                :data="cardItems"
                :current-timestamp="currentTimestamp"
                :available-free-packs="availableFreePacks"
                @select="selectedItemId = $event"
            />

            <HardCurrencyPurchaseItemInfo
                v-if="selectedItem && isVisiblePurchaseWindow"
                :="selectedItem"
                :is-free-pack-available="availableFreePacks.includes(selectedItem.id)"
                :free-pack-state="freePackState"
                :current-timestamp="currentTimestamp"
                @buy="$emit('buy', $event)"
                @back="selectedItemId = undefined"
            />

            <PurchaseResult
                v-if="isVisiblePurchaseResult"
                :store-state="storeState"
                :free-pack-state="freePackState"
                @done="handleDoneResultPopup"
            />

            <HardCurrencyPurchaseFooter
                v-if="!isVisiblePurchaseResult"
                @close="emit('close')"
            />
        </div>

        <CommonLoading
            v-if="storeState === PREMIUM_STORE_STATE.LOADING"
            class="purchase-popup__loading"
            text="Loading..."
        />
        <CommonLoading
            v-if="storeState === PREMIUM_STORE_STATE.PURCHASING || freePackState === 'purchasing'"
            class="purchase-popup__loading"
            text="Purchasing..."
        />
    </div>
</template>

<style lang="scss" scoped>
.purchase-popup {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 12;

    background: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(11px);

    display: flex;
    justify-content: center;
    align-items: center;

    &__inner {
        width: size(981px);
        max-height: size(782px);
        border-radius: size(17.5px);
        border: size(1.25px) solid rgba(255, 255, 255, 0.22);
        background: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(34.3px);
        min-height: size(100px);

        &--secondary {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), rgba(255, 255, 255, 0.11);
            backdrop-filter: blur(34px);
            width: size(748px);
            height: size(427px);
            display: flex;
            align-items: center;
        }
    }

    &__loading {
        position: absolute;
        z-index: 1;

        width: 100%;
        height: 100%;

        backdrop-filter: blur(size(2px));
        pointer-events: all;
    }
}
</style>
