<script lang="ts" setup>
const refCode = defineModel<string | undefined>();
</script>

<template>
    <div class="ref-code">
        <!-- <h1 class="ref-code__title">
            FRIEND CODE <span class="ref-code__title--dark">(OPTIONAL)</span>
        </h1> -->

        <input
            v-model="refCode"
            class="ref-code__input"
            :class="{ 'ref-code__input--fill': !!refCode }"
            placeholder="INVITE CODE (OPTIONAL)"
        >
    </div>
</template>

<style lang="scss" scoped>
.ref-code {
    &__title {
        margin-bottom: size(11px);

        color: rgba($color-white, 0.77);
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: size(1.76px);

        &--dark {
            color: rgba($color-white, 0.33);
        }
    }

    &__input {
        width: 100%;
        height: size(59px);

        padding: size(14px) size(17px);

        background: rgba($color-white, 0.04);
        border: solid size(1px) rgba($color-white, 0.22);
        border-radius: size(4.6px);

        color: rgba($color-white, 0.77);
        font-family: $font-family-base;
        font-size: size(18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.98px);

        transition: background $base-transition;

        outline-color: transparent;
        outline-style: none;

        &--fill {
            background: rgba($color-white, 0.11);
        }

        &::placeholder {
            color: rgba($color-white, 0.33);
        }
    }
}
</style>
