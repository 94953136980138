<script setup lang="ts">
import { CardInfo } from '~/types/hardCurrencyPurchase';

const props = defineProps<CardInfo & {
    isFreePackAvailable: boolean;
    freePackState: 'purchasing' | 'success' | 'error' | undefined;
    currentTimestamp: number;
}>();

defineEmits<{
    (e: 'back'): void
    (e: 'buy', id: string): void
}>();
</script>

<template>
    <div class="item-info">
        <HardCurrencyPurchaseCard
            v-bind="props"
            highlighted
            readonly
            is-available
        />
        <div class="item-info__description">
            <div class="item-info__name">
                <h1 class="prop-header">
                    ITEM
                </h1>

                <span>
                    {{ name }}

                    <span v-if="flashSaleSetting?.enabled">
                        (FLASH SALE)
                    </span>
                    <span v-else-if="dailySale">
                        (DAILY PACK)
                    </span>
                </span>
            </div>

            <div class="item-info__cost">
                <h1 class="prop-header">
                    COST
                </h1>

                <span>
                    {{ price === '0' ? 'FREE' : `${price} ${currencyName}` }}

                    <span v-if="oldPrice && +price !== 0">
                        {{ oldPrice }} {{ currencyName }}
                    </span>
                </span>
            </div>

            <div
                v-if="desc"
                class="item-info__item-desc"
            >
                <h1 class="prop-header">
                    DETAIL
                </h1>

                <span>
                    {{ desc }}
                </span>
            </div>

            <div class="item-info__buttons-container">
                <CommonButtonCorneredRoundedGray
                    class="item-info__back-btn"
                    @click="$emit('back')"
                >
                    <span class="item-info__back-btn-text">
                        BACK
                    </span>
                </CommonButtonCorneredRoundedGray>

                <CommonButtonCorneredRounded
                    class="item-info__purchase-btn"
                    :is-enabled="!freePack || (isFreePackAvailable && freePackState !== 'purchasing')"
                    @click="$emit('buy', id)"
                >
                    <span class="item-info__purchase-btn-text">
                        PURCHASE
                    </span>
                </CommonButtonCorneredRounded>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.item-info {
    display: flex;
    gap: size(45px);

    padding: 0 size(45px) size(29px)size(44px);

    &__buttons-container {
        display: flex;
        gap: size(17px);

        margin-top: auto;
    }

    &__back-btn {
        width: size(249.3px);
        height: size(50px);

        --background-color: rgba(0, 0, 0, 0.11);

        &-text {
            color: rgba(255, 255, 255, 0.77);
            text-align: center;
            font-family: $font-family-base;
            font-size: size(18px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: size(1.98px);
        }
    }

    &__purchase-btn {
        width: size(249.3px);
        height: size(50px);

        &-text {
            color: #000;
            text-align: center;
            text-shadow: 0 0 14.472px rgba(255, 255, 255, 0.33);
            font-family: $font-family-base;
            font-size: size(18px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: size(1.98px);
        }
    }

    &__description {
        height: auto;
        width: size(517px);
        display: flex;
        flex-direction: column;
        padding-bottom: size(15px);
    }

    &__name {
        display: flex;
        flex-direction: column;
        margin-top: size(22px);
        gap: size(3px);

        span {
            display: flex;
            gap: size(8px);

            color: rgba(255, 255, 255, 0.77);
            text-shadow: 0 0 size(19px) rgba(255, 255, 255, 0.33);
            font-family: $font-family-base;
            font-size: size(24px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: size(2.64px);

            span {
                color: $color-primary;
                font-size: size(16px);
                letter-spacing: size(1.76px);
                align-items: end;
                margin-bottom: size(3px);
            }
        }
    }

    &__cost {
        display: flex;
        flex-direction: column;
        margin-top: size(14px);

        span {
            display: flex;
            gap: size(11px);

            color: $color-primary;
            text-shadow: 0 0 size(19px) rgba(255, 255, 255, 0.33);
            font-family: $font-family-base;
            font-size: size(24px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: size(2.6px);

            text-transform: uppercase;

            align-items: center;

            span {
                text-shadow: none;
                color: rgba(255, 255, 255, 0.44);
                font-size: size(16px);
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: size(1.76px);
                text-decoration-line: line-through;
                text-transform: uppercase;
            }
        }
    }

    &__item-desc {
        display: flex;
        flex-direction: column;
        margin-top: size(16px);
        gap: size(3px);

        span {
            width: 100%;

            color: rgba(255, 255, 255, 0.77);
            font-family: $font-family-base;
            font-size: size(16px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: size(1.76px);

            text-transform: uppercase;
        }
    }
}

.prop-header {
    color: rgba(255, 255, 255, 0.44);
    font-family: $font-family-base;
    font-size: size(16px);
    font-style: normal;
    font-weight: 600;
    line-height: 121%;
    letter-spacing: size(1.76px);
}
</style>
