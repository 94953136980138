<!-- eslint-disable max-len -->

<!-- TODO: Because it's not possible to display gradient via @nuxtjs/svg-sprite https://github.com/nuxt-modules/svg-sprite/issues/4#issuecomment-513820435 -->

<template>
    <svg viewBox="0 0 20 19">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.000393302 8.07507L2.28548 0.469795L18.9459 0.470292L16.6608 8.07557L19.7616 8.07566L16.8237 18.1673L2.43042e-05 18.1668L3.0196 8.07516L0.000393302 8.07507ZM8.70986 5.7126L15.0531 5.71279L14.4475 7.92902L8.10422 7.92883L8.70986 5.7126ZM16.797 9.86659L10.3957 9.8664L9.84826 12.0874L16.2496 12.0876L16.797 9.86659Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.000393302 8.07507L2.28548 0.469795L18.9459 0.470292L16.6608 8.07557L19.7616 8.07566L16.8237 18.1673L2.43042e-05 18.1668L3.0196 8.07516L0.000393302 8.07507ZM8.70986 5.7126L15.0531 5.71279L14.4475 7.92902L8.10422 7.92883L8.70986 5.7126ZM16.797 9.86659L10.3957 9.8664L9.84826 12.0874L16.2496 12.0876L16.797 9.86659Z"
            fill="url(#gradient_linear_16140_2586)"
        />
        <defs>
            <linearGradient
                id="gradient_linear_16140_2586"
                x1="2.12947"
                y1="0.52788"
                x2="15.0072"
                y2="19.3986"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FC98D4" />
                <stop
                    offset="0.1611"
                    stop-color="#F09CDD"
                />
                <stop
                    offset="0.4496"
                    stop-color="#D1A7F5"
                />
                <stop
                    offset="0.4761"
                    stop-color="#CEA8F7"
                />
                <stop
                    offset="0.7217"
                    stop-color="#D7E8FA"
                />
                <stop
                    offset="0.8851"
                    stop-color="#EBF5DE"
                />
                <stop
                    offset="1"
                    stop-color="#F5FCD0"
                />
            </linearGradient>
        </defs>
    </svg>
</template>
