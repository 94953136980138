<script setup lang="ts">
withDefaults(defineProps<{
    isEnabled?: boolean;
}>(), {
    isEnabled: true,
});

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();
</script>

<template>
    <CommonButton
        class="button"
        :corners="{
            tl: { type: 'round', size: 6 },
            tr: { type: 'round', size: 6 },
            br: { type: 'round', size: 6 },
            bl: { type: 'round', size: 6 },
        }"
        :is-enabled="isEnabled"

        @click="emit('click', $event)"
    >
        <SvgIcon
            class="button__logo"
            name="logo"
        />
    </CommonButton>
</template>

<style scoped lang="scss">
.button {
    --background-color: #{$color-primary};
    --hover-background-color: #{lighten($color-primary, 5%)};
    --active-background-color: #{darken($color-primary, 5%)};

    &__logo {
        width: size(123px);
        height: size(24.6px);

        color: black;
    }
}
</style>
