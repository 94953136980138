<script lang="ts" setup>
defineProps<{
    placeholder?: string;
}>();

const emit = defineEmits(['submit']);

const value = defineModel<string | undefined>();
</script>

<template>
    <div
        class="input"
        :class="{ 'input--fill': !!value }"
    >
        <input
            v-model="value"
            class="input__input"
            :placeholder="placeholder"
        >

        <div
            class="input__button"
            :class="{ 'input__button--fill': !!value }"
            @click="!!value && emit('submit')"
        >
            >
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: rgba($color-white, 0.04);
    border: solid size(1px) rgba($color-white, 0.22);
    border-radius: size(4.6px);

    transition: background $base-transition;

    padding-right: size(7px);

    &--fill {
        background: rgba($color-white, 0.11);
    }

    &__input {
        width: calc(100% - size(46px));
        height: 100%;

        padding: size(14px) size(17px);

        color: rgba($color-white, 0.77);
        font-family: $font-family-base;
        font-size: size(18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.98px);

        &::placeholder {
            color: rgba($color-white, 0.33);
            font-family: $font-family-base;
            font-size: size(18px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: size(1.98px);
        }

        background: transparent;
        border: none;
        outline-color: transparent;
        outline-style: none;
    }

    &__button {
        flex: 0 0 size(45px);
        height: size(45px);

        background: rgba($color-white, 0.07);
        border: solid size(1px) rgba($color-white, 0.33);
        border-radius: size(7px);
        opacity: 0.33;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        transition: opacity $base-transition, background $base-transition, color $base-transition, box-shadow $base-transition;

        color: rgba($color-white, 0.44);
        font-family: $font-family-base;
        font-size: size(33px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: size(3.63px);

        padding-left: size(4px);

        &--fill {
            background: $color-primary;
            color: rgba($color-black, 0.77);
            opacity: 1;

            &:hover {
                border: size(1px) solid $color-primary;
                background: lighten($color-primary, 20%);
                box-shadow: 0 0 size(11px) 0 rgba($color-white, 0.25);
            }
        }
    }
}
</style>
