<script lang="ts" setup>
import { useAuthStore } from '~/stores/auth';

import loginBgUrl from '/images/loginBg.webp?url';

import { ENV_DEV } from '~/utils/env';
import { authService, AuthType } from '~/services/authService';
import { KeycloackLoginType } from '~/services/authService/keycloakProvider';

useHead({
    link: [
        { rel: 'preload', as: 'image', href: loginBgUrl },
    ],
});

const authStore = useAuthStore();

const {
    isLoggedIn,
} = storeToRefs(authStore);

const referrerCode = ref<string | undefined>(undefined);
const lastJoinedName = ref<string | undefined>(randomElement(randomUserNames));
const isTwitter = ref(true);
const resendTimestamp = ref<number | undefined>(undefined);

// TODO: refactor
const extractReferrerCodeFromUrl = () => {
    return location?.search
        ?.split('?').pop()
        ?.split('&')
        .findLast(param => param.startsWith('referrer='))
        ?.split('=')?.pop();
};

onMounted(() => {
    referrerCode.value = extractReferrerCodeFromUrl();
    if (referrerCode.value) {
        console.log('Referrer code:', referrerCode.value);
    }

    // old login method
    if (ENV_DEV) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).login = () => authService.login({ type: AuthType.Keycloak, loginType: KeycloackLoginType.walletLogin });
    }
});
</script>

<template>
    <div
        v-show="!isLoggedIn"
        class="login"
    >
        <div class="login__main main">
            <template v-if="lastJoinedName">
                <span class="main__joined joined">
                    <SvgIcon
                        class="joined__icon"
                        name="fire"
                    />

                    <span class="joined--highlight">@{{ lastJoinedName }}</span>&nbsp;has joined
                </span>
            </template>

            <div class="main__prompt">
                <template v-if="isTwitter">
                    <LoginTwitter
                        :init-referrer-code="referrerCode"
                        @switch="isTwitter = false"
                    />
                </template>
                <template v-else>
                    <LoginOther
                        :init-referrer-code="referrerCode"
                        :resend-timestamp="resendTimestamp"
                        @switch="isTwitter = true"
                        @start-timer="resendTimestamp = Date.now() + LOGIN_RESEND_TIMEOUT_S * 1000"
                    />
                </template>
            </div>

            <div class="main__partners partners">
                <h1 class="partners__label">
                    OUR PARTNERS
                </h1>

                <div class="partners__list">
                    <SvgIcon
                        class="partners__icon"
                        name="logo1"
                    />

                    <SvgIcon
                        class="partners__icon"
                        name="logo2"
                    />

                    <SvgIcon
                        class="partners__icon"
                        name="logo3"
                    />

                    <SvgIcon
                        class="partners__icon"
                        name="logo4"
                    />

                    <NuxtImg
                        class="partners__icon"
                        src="/images/logo5.png"
                        alt="logo5"
                    />
                </div>

                <div class="partners__divider" />

                <h1 class="partners__label">
                    CONNECT TO LOGIN OR AUTO-CREATE ACCOUNT,<br>
                    AGREEING TO OUR <span class="partners__label--h">
                        TERMS
                    </span> AND <span class="partners__label--h">
                        PRIVACY POLICY.
                    </span>
                </h1>
            </div>
        </div>

        <div class="login__bg" />
    </div>

    <slot />
</template>

<style lang="scss" scoped>
.login {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;

    z-index: 100;

    display: flex;
    align-items: stretch;

    background: #0C0800;

    &__main {
        flex: 0 0 size(601px);
    }

    &__bg {
        flex: 1;

        background-image: url('/images/loginBg.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.main {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    &__joined {
        grid-row: 1;
        grid-column: 1;

        align-self: start;
        justify-self: center;

        margin-top: size(47.24px);
    }

    &__prompt {
        grid-row: 1;
        grid-column: 1;

        align-self: center;
        justify-self: center;
    }

    &__partners {
        grid-row: 1;
        grid-column: 1;

        align-self: end;
        justify-self: center;

        margin-bottom: size(45px);
    }
}

.joined {
    display: flex;
    align-items: center;
    justify-content: center;

    color: rgba(255, 255, 255, 0.77);
    font-family: $font-family-base;
    font-size: size(18.581px);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: size(2.044px);

    text-transform: uppercase;

    &--highlight {
        color: $color-primary;
    }

    &__icon {
        margin-right: size(12.7px);

        width: size(20.171px);
        height: size(25.242px);

        color: $color-primary;
    }
}

.partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__label {
        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);

        text-transform: uppercase;

        &--h {
            color: rgba($color-white, 0.77);
        }
    }

    &__list {
        margin-top: size(15px);

        display: flex;
        align-items: center;
        justify-content: center;

        gap: size(10px);
    }

    &__icon {
        width: size(37px);
        height: size(37px);

        color: #ACABA8;
    }

    &__divider {
        margin-top: size(25px);
        margin-bottom: size(25px);

        width: size(364px);
        height: size(1px);

        background: rgba($color-white, 0.1);
    }
}
</style>
