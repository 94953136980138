<script setup lang="ts">
withDefaults(defineProps<{
    isEnabled?: boolean;
}>(), {
    isEnabled: true,
});

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();
</script>

<template>
    <CommonButton
        class="button"
        :corners="{
            tl: { type: 'round', size: 4 },
            tr: { type: 'round', size: 4 },
            br: { type: 'angle', size: 26 },
            bl: { type: 'round', size: 4 },
        }"
        :border="{
            type: 'solid',
            width: 1.5,
        }"
        :is-enabled="isEnabled"

        @click="emit('click', $event)"
    >
        <SvgIcon
            class="button__logo"
            name="x"
        />

        <h1 class="button__text">
            <slot />
        </h1>
    </CommonButton>
</template>

<style scoped lang="scss">
.button {
    --background-color: #{rgba($color-white, 0.04)};
    --hover-background-color: #{$color-primary};
    --active-background-color: #{darken($color-primary, 5%)};

    --border-color: #{$color-primary};

    &__logo {
        margin-right: size(14px);

        width: size(23.337px);
        height: size(22.804px);

        color: $color-primary;
        opacity: 0.77;

        transition: color $base-transition;
    }

    &__text {
        color: $color-primary;
        text-align: center;
        font-family: $font-family-base;
        font-size: size(24px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: size(2.64px);

        text-transform: uppercase;

        transition: color $base-transition;
    }

    &:hover &__text {
        color: black;
    }

    &:active &__text {
        color: black;
    }

    &:hover &__logo {
        color: black;
    }

    &:active &__logo {
        color: black;
    }
}
</style>
