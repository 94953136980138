export interface AbstractCardInfo {
    id: string;
    iconParams: IconParams;
    name: string;
    amount: number;
    currencyName: string;
    oldPrice?: number;
    desc?: string;
    bonus?: number;
    flashSaleSetting?: { enabled: boolean, endDate?: string };
    dailySale: boolean;
}

export interface CardInfo extends AbstractCardInfo {
    price: string;
    freePack?: string;
}

interface IconParams {
    name: string;
    iconSize?: { w: number, h: number, mt?: number };
}

export const HARD_CURRENCY_ICONS_PARAMS: Record<number, IconParams> = {
    1: { name: 'images/hard-currencies/bytes_pack_1.webp', iconSize: { w: 131.1, h: 92.7, mt: 61 } },
    2: { name: 'images/hard-currencies/bytes_pack_2.webp', iconSize: { w: 150.8, h: 92.2, mt: 61 } },
    3: { name: 'images/hard-currencies/bytes_pack_3.webp', iconSize: { w: 152.2, h: 108.35, mt: 42 } },
    4: { name: 'images/hard-currencies/bytes_pack_4.webp', iconSize: { w: 186.18, h: 115.8, mt: 39 } },
    5: { name: 'images/hard-currencies/bytes_pack_5.webp', iconSize: { w: 171.1, h: 110.5, mt: 47 } },
    6: { name: 'images/hard-currencies/bytes_pack_6.webp', iconSize: { w: 187.28, h: 133.5, mt: 24 } },
} as const;
