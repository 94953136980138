import { TUTORIAL_ELEMENTS, TutorialProps } from '~/types/tutorial';

export const useTutorialConfigs = (): TutorialProps['steps']  => [
    {
        header: 'WELCOME TO BITHUB',
        secondHeader: 'THE FUTURE HUB OF CRYPTO BROWSER GAMES',
        text: [
            'RIGHT NOW, THE NAME OF THE GAME IS MAXIMISING YOUR BOOST AND',
            {
                smartString: getSmartString('EARNING [BYTES] - COLLECT A LARGE NUMBER OF BYTES, AND YOU MIGHT'),
                highlightStyle: { color: '#69FFE4' },
            },
            'BE ABLE TO CONVERT THEM TO [#######] OR [#######] SOON.',
            'THERE’S MANY WAYS YOU CAN EARN BYTES, FROM MINING, TO INVITING',
            'YOUR FRIENDS OR COLLECTING THEM IN THE GAME, AND MORE.',
        ],
    },
    {
        header: 'GAINING BOOST',
        secondHeader: 'BOOST MAXXING',
        text: [
            'YOUR ULTIMATE OBJECTIVE IS TO INCREASE YOUR TOTAL',
            {
                smartString: getSmartString('[BOOST]{boost}. BOOST IS THE BEST WAY TO INCREASE YOUR '),
                highlightStyle: { color: '#FFF' },
                iconStyle: {
                    color: '#FFF',
                    width: 'calc(var(--scale) * 18px)',
                    height: 'calc(var(--scale) * 18px)',
                    marginLeft: 'calc(var(--scale) * 5px)',
                },
            },
            'BYTE EARNINGS. BOOST IS A MULTIPLIER THAT APPLIES',
            'GLOBALLY TO ANY BYTE REWARD YOU EARN.',
            '',
            {
                smartString: getSmartString('YOUR BOOST CAN BE INCREASED BY COMPLETING [AWAKENING]'),
                highlightStyle: { color: '#FFF' },
            },
            {
                smartString: getSmartString('[MISSIONS, INVITING PLAYERS] TO YOUR CREW, EARNING'),
                highlightStyle: { color: '#FFF' },
            },
            {
                smartString: getSmartString('REWARDS FROM THE [BATTLE PASS] AND VIA [DAILY LOGINS].'),
                highlightStyle: { color: '#FFF' },
            },
        ],
        elToShow: TUTORIAL_ELEMENTS.BOOST,
    },
    {
        header: 'EARNING BYTES',
        secondHeader: 'BOOSTED? TIME TO EARN SOME BYTES',
        text: [
            'BYTES CAN BE EARNED IN A VARIETY OF WAYS. THEY CAN BE',
            'MINED PASSIVELY, THE EASIEST WAY TO EARN BYTES IS VIA',
            {
                smartString: getSmartString('[PASSIVE MINING WITH BITBOT], JUST CLICK ON A BUTTON AND'),
                highlightStyle: { color: '#FFF' },
            },
            'YOUR BYTES WILL START ROLLING IN, EASY RIGHT?',
            '',
            'YOU CAN ALSO EARN MORE BYTES BY COMPLETING BATTLE PASS',
            'MISSIONS, THE AWAKENING MISSIONS, LOGGING IN DAILY AND',
            'INVITING YOUR FRIENDS. BE SURE TO TAKE A LOOK AROUND!',
        ],
        elToShow: TUTORIAL_ELEMENTS.MINING,
    },
];
