<script setup lang="ts">
import { TutorialProps } from '~/types/tutorial';

const emit = defineEmits(['close', 'onChange']);

const props = defineProps<TutorialProps>();

const currentStepIndex = ref(props.initialTutorialStep);

const currentStep = computed(() => props.steps[currentStepIndex.value]);

const handleClickBtn = () => {
    if (currentStepIndex.value !== props.steps.length - 1) {
        currentStepIndex.value++;
        emit('onChange', currentStep.value?.elToShow);
        return;
    }

    emit('close');
};

const handleBackBtn = () => {
    currentStepIndex.value--;
    emit('onChange', currentStep.value?.elToShow);
};

onMounted(() => {
    emit('onChange', currentStep.value?.elToShow);
});
</script>

<template>
    <div class="tutorial">
        <CommonButtonHome class="tutorial__home-btn" />

        <div
            v-show="steps.length > 1 && currentStepIndex > 0 && tutorialBackButton"
            class="tutorial__back-btn"
            @click="handleBackBtn"
        >
            <SvgIcon
                class="tutorial__back-btn-icon"
                name="arrow"
            />
            <span>
                BACK
            </span>
        </div>

        <div class="tutorial__info">
            <span class="tutorial__info-header">
                {{ currentStep.header }}
            </span>

            <span class="tutorial__info-header-second">
                {{ currentStep.secondHeader }}
            </span>

            <CommonText
                class="tutorial__info-text"
                :text="currentStep.text"
            />

            <CommonButtonCornered
                class="tutorial__info-button"
                @click="handleClickBtn"
            >
                <span class="tutorial__info-button-text">
                    {{ currentStepIndex !== props.steps.length - 1 ? 'NEXT' : 'FINISH' }}
                </span>
            </CommonButtonCornered>
        </div>

        <div
            v-if="currentStepIndex === 0"
            class="tutorial__blackout tutorial__blackout--1"
        />
        <div
            v-if="currentStepIndex === 0"
            class="tutorial__blackout tutorial__blackout--2"
        />
    </div>
</template>

<style lang="scss" scoped>
.tutorial {
    height: 100%;
    width: 100%;
    z-index: 20;
    position: absolute;

    background: linear-gradient(0deg, rgba(0, 0, 0, 0.44) 0%,
        rgba(0, 0, 0, 0.44) 100%), radial-gradient(50% 50% at 50% 50%,
        rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.77) 100%);

    &__back-btn {
        display: flex;
        gap: size(8px);
        cursor: pointer;

        left: size(115px);
        top: size(288px);
        position: absolute;

        margin-bottom: size(33px);

        &-icon {
            height: size(17px);
            width: size(11px);
            color: #FFF;
            rotate: 180deg;
        }

        span {
            color: #FFF;
            font-family: $font-family-base;
            font-size: size(18px);
            font-style: normal;
            font-weight: 600;
            line-height: 93.377%;
            letter-spacing: size(0.72px);
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        left: size(115px);
        top: size(375px);
        position: absolute;

        &-text {
            color: rgba(255, 255, 255, 0.66);
            font-family: $font-family-base;
            font-size: size(18px);
            font-style: normal;
            font-weight: 500;
            line-height: 131%;
            letter-spacing: size(0.72px);
            margin-top: size(12px);
        }

        &-header {
            color: #FFF;
            font-family: $font-family-radion-a;
            font-size: size(84px);
            font-style: normal;
            font-weight: 900;
            line-height: 93.377%;
            letter-spacing: size(3.36px);

            &-second {
                color: #FFF;
                font-family: $font-family-base;
                font-size: size(24px);
                font-style: normal;
                font-weight: 500;
                line-height: 131%;
                letter-spacing: size(0.96px);

                margin-top: size(44px);
            }
        }

        &-button {
            margin-top: size(78px);
            width: size(240px);
            height: size(54px);

            &-text {
                color: rgba(0, 0, 0, 0.77);
                text-align: center;
                font-family: $font-family-base;
                font-size: size(18px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: size(1.98px);
            }
        }
    }

    &__home-btn {
        flex: 0 0 size(154px);
        height: size(46px);
        width: size(154px);

        margin-top: size(38px);
        margin-left: size(38px);
    }

    &__blackout {
        position: absolute;
        background: $color-primary;
        width: size(100px);
        height: size(21px);

        &--1 {
            left: size(428px);
            top: size(590px);
        }

        &--2 {
            left: size(578px);
            top: size(590px);
        }
    }
}
</style>
