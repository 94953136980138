<script setup lang="ts">
defineProps<{
    dailyLevel: number;
    bytes: number;
    bits: number;
    haveAirDropChance: boolean;
    isOpenTutorial: boolean;
    multiplier: number;
    profile: {
        avatar: string;
        level: number;
    }
}>();

const emit = defineEmits([
    'home',
    'play',
    'addBits',
    'account',
    'openTutorial',
]);
</script>

<template>
    <div class="header">
        <HeaderButtons
            class="header__buttons"
            @home="emit('home')"
            @play="emit('play')"
        />

        <HeaderTabs class="header__tabs" />

        <HeaderInfo
            class="header__info"
            :daily-level="dailyLevel"
            :bits="bits"
            :bytes="bytes"
            :profile="profile"
            :have-air-drop-chance="haveAirDropChance"
            :is-open-tutorial="isOpenTutorial"
            :multiplier="multiplier"
            @add-bits="emit('addBits')"
            @account="emit('account')"
            @open-tutorial="emit('openTutorial')"
        />
    </div>
</template>

<style lang="scss" scoped>
.header {
    height: size(77px);
    border-radius: size(14px);
    background: rgba(255, 255, 255, 0.07);

    padding: 0 size(16px);

    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    &__buttons {
        grid-row: 1;
        grid-column: 1;

        align-self: center;
        justify-self: start;
    }

    &__tabs {
        grid-row: 1;
        grid-column: 1;

        align-self: center;
        justify-self: center;
    }

    &__info {
        grid-row: 1;
        grid-column: 1;

        align-self: center;
        justify-self: end;
    }
}
</style>
