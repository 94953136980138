<script setup lang="ts">
import { AdminConfig } from '~/services/achievementsAdminService/config';
import { VIDEO_ANIMATIONS } from '~/constants/videoAnimationNames';
import achievementBgUrl from '/images/backgrounds/achievement-background.webp?url';
import accountBgUrl from '/images/backgrounds/account.png?url';
import bitBotBgFirst from '/video/bit-bot/bit-bot_bg.webm?url';
import { useTutorialConfigs } from '~/composables/useTutorialConfigs';
import { useTutorialStore } from '~/stores/tutorial';
import { TUTORIAL_ELEMENTS } from '~/types/tutorial';
import { CardInfo, HARD_CURRENCY_ICONS_PARAMS } from '~/types/hardCurrencyPurchase';
import { achievementsService } from '~/services/achievementsService';
import { hardCurrencyPremiumStore } from '~/utils/constants';
import dayjs from 'dayjs';
import { ENV_DEV } from '~/utils/env';
import eventService from '~/services/eventService';

useHead({
    link: [
        { rel: 'preload', as: 'image', href: achievementBgUrl },
        { rel: 'preload', as: 'image', href: accountBgUrl },
        { rel: 'preload', as: 'video', href: bitBotBgFirst },
    ],
});

const route = useRoute();
const achievementsStore = useAchievementsStore();
const tutorialStore = useTutorialStore();
const premiumStore = usePremiumStore();
const authStore = useAuthStore();
const inventoryStore = useInventoryStore();
const { homeUrl } = useRemoteConfigStore();

const dailyRewards = computed(() => achievementsStore.dailyLogin.rewards);
const tutorialEnabled = computed(() => tutorialStore.tutorial.enabled);
const freePacks = computed(() => achievementsStore.freePacks.packs);
const rewardStatusMap = computed(() => achievementsStore.rewards.missionStatusMap);
const rewardStatusesMap = computed(() => achievementsStore.rewards.missionStatusesMap);

const freePackState = ref<'purchasing' | 'success' | 'error' | undefined>(undefined);
const iframeData = ref<{ iframe: HTMLIFrameElement; close: () => void } | undefined>(undefined);

const tutorialBackButton = ref(true);

const bits = computed(() => inventoryStore.inventory.items.find(item => item.itemClass.alias === CURRENCIES.BITS)?.quantity ?? 0);

const currentDailyMission = computed(() => {
    const currentRewardIndex = dailyRewards.value.findLastIndex(reward => reward.progress === reward.target);

    if (currentRewardIndex === -1) {
        return dailyRewards.value.length;
    }

    return currentRewardIndex + 1;
});

const animationBgConfig = useBitBotAnimationConfig();

const hardCurrencyPopupItems = computed<CardInfo[]>(
    () => [
        ...hardCurrencyPremiumStore.categories[0].items
            .map((item, index) => {
                const maxIndex = Math.max(...Object.keys(HARD_CURRENCY_ICONS_PARAMS).map(key => +key));
                const iconParams = index + 1 > maxIndex
                    ? HARD_CURRENCY_ICONS_PARAMS[maxIndex]
                    : HARD_CURRENCY_ICONS_PARAMS[index + 1];
                const storeItem = premiumStore.storeItems.items[item.id];

                if (!storeItem) {
                    return null;
                }

                return {
                    id: item.id,
                    iconParams,
                    name: item.name,
                    amount: storeItem.quantity,
                    currencyName: storeItem.currency,
                    oldPrice: item.oldPriceAmount,
                    desc: item.description,
                    bonus: item.bonusQuantity,
                    flashSaleSetting: { enabled: !!item.flashSaleDate, endDate: item.flashSaleDate },
                    // dailySaleDate: item.dailySaleDate,
                    dailySale: false,
                    price: storeItem.price,
                } satisfies CardInfo;
            }).filter(isDefined),
        ...Object.entries(achievements.freePacks).map(([key, pack]) => {
            const mission = freePacks.value[key];
            if (!mission) {
                return null;
            }

            return {
                id: key,
                iconParams: HARD_CURRENCY_ICONS_PARAMS[1],
                name: pack.name,
                amount: pack.item.amount,
                currencyName: '',
                desc: pack.description,
                dailySale: true,
                price: 'Free',
                freePack: key,
            } as CardInfo;
        }).filter(isDefined),
    ],
);

const availableFreePacks = computed(
    () => Object.keys(achievements.freePacks)
        .filter(key => freePacks.value[key] && (freePacks.value[key].progress < freePacks.value[key].target)),
);

watch(() => achievementsStore.isInited, async isInited => {
    if (isInited) {
        achievementsStore.fetchBitBotStatus();
        achievementsStore.handleShowTutorial();

        await achievementsStore.fetchAll();
    }
});

watch(() => inventoryStore.isInited, async isInited => {
    if (isInited) {
        await inventoryStore.fetchInventory();
    }
});

onMounted(() => {
    if (ENV_DEV) {
        new AdminConfig();
    }

    eventService.premiumStore.on('showIframe', onShowIframe);
    eventService.premiumStore.on('closeIframe', onCloseIframe);
});

onBeforeUnmount(() => {
    eventService.premiumStore.off('showIframe', onShowIframe);
    eventService.premiumStore.off('closeIframe', onCloseIframe);
});

const tutorialSteps = useTutorialConfigs();

const handleFinishTutorial = () => {
    tutorialBackButton.value = true;
    tutorialStore.toggleOpenTutorial(false);
    tutorialStore.setTutorialEnabledElements([]);
    achievementsStore.sendFinishTutorialEvent();
};

const handleOpenTutorialAtMining = async () => {
    await navigateTo('/');

    tutorialBackButton.value = false;
    tutorialStore.setInitialTutorialStep(2);
    tutorialStore.setTutorialEnabledElements(['header']);
    tutorialStore.toggleOpenTutorial(true);
};

const handleDoneClick = () => {
    inventoryStore.fetchInventory();
    premiumStore.toggleHardCurrency(false);
    premiumStore.resetState();
    freePackState.value = undefined;
};

const handleBuyHardCurrency = async (id: string) => {
    const cardInfo = hardCurrencyPopupItems.value.find(item => item.id === id);
    if (!cardInfo) {
        return;
    }

    if (cardInfo.freePack) {
        const mission = freePacks.value[cardInfo.freePack];

        freePackState.value = 'purchasing';

        try {
            await achievementsService.postEvent({ code: ClientEvents.FREE_PACK, payload: { key: id } });

            await retry(
                async () => await Promise.allSettled([
                    achievementsStore.fetchRewards(),
                ]),
                () => Object.values(rewardStatusesMap.value[mission.mission.id]).includes('WAITING_FOR_CLAIM'),
                { count: 10, timeout: 1000 },
            );

            await achievementsStore.claimMissionRewards(mission.mission.id);

            await retry(
                async () => await Promise.allSettled([
                    achievementsStore.fetchRewards(),
                ]),
                () => rewardStatusMap.value[mission.mission.id] === 'GRANTED',
                { count: 10, timeout: 1000 },
            );

            freePackState.value = 'success';
        } catch (e) {
            freePackState.value = 'error';
        }

        await Promise.allSettled([
            achievementsStore.fetchFreePacks(),
            inventoryStore.fetchInventory(),
        ]);
        return;
    }

    await premiumStore.purchaseItem(id);
};

const handleChangeTutorialElements = (element?: string) => {
    tutorialStore.setTutorialEnabledElements(element ? [element] : []);
};

const onShowIframe = ({ iframe, close }: { iframe: HTMLIFrameElement; close: () => void }) => {
    iframeData.value = { iframe, close };
};

const onCloseIframe = () => {
    iframeData.value = undefined;
};

const navigateToHome = () => navigateTo(homeUrl || '/', { external: true });
const navigateToAccount = () => navigateTo('/account');
</script>

<template>
    <Login v-if="authStore.isInited">
        <div
            class="root"
            :class="{ 'root--bg-account': route.path === '/account' }"
        >
            <div
                v-if="route.path === '/'"
                class="root__vignette"
                :class="[{ 'root__vignette--blured': tutorialEnabled }]"
            />

            <Tutorial
                v-if="route.path === '/' && tutorialEnabled"
                :steps="tutorialSteps"
                :initial-tutorial-step="tutorialStore.tutorial.initialTutorialStep"
                :enabled-elements="tutorialStore.tutorial.enabledElements"
                :tutorial-back-button="tutorialBackButton"
                @on-change="handleChangeTutorialElements"
                @close="handleFinishTutorial"
            />

            <VideoAnimation
                v-show="route.path === '/'"
                class="root__video"
                :class="[{ 'root__video--blured': tutorialEnabled }]"
                :name="VIDEO_ANIMATIONS.BitBot"
                :src-set="[
                    '/video/bit-bot/bit-bot_bg.webm',
                    '/video/bit-bot/bit-bot_bg.mp4',
                ]"
                :config="animationBgConfig"
            />

            <div
                class="root__header"
                :class="[
                    { 'tutorial-element': tutorialEnabled },
                    { 'tutorial-element--enabled': tutorialStore.tutorial.enabledElements.includes(TUTORIAL_ELEMENTS.HEADER) }
                ]"
            >
                <Header
                    :daily-level="currentDailyMission"
                    :bits="bits"
                    :bytes="achievementsStore.user.points"
                    :profile="{ avatar: '/images/avatars/small.png', level: -1 }"
                    :have-air-drop-chance="achievementsStore.isHaveAirDropChance"
                    :is-open-tutorial="tutorialEnabled"
                    :multiplier="achievementsStore.user.pointsMultiplier"
                    @add-bits="premiumStore.toggleHardCurrency(true)"
                    @account="navigateToAccount"
                    @home="navigateToHome"
                    @open-tutorial="handleOpenTutorialAtMining"
                />
            </div>

            <HardCurrencyPurchase
                v-show="premiumStore.hardCurrencyPopupOpened"
                title="BITPACK SALE"
                :bits="bits"
                :items="hardCurrencyPopupItems"
                :store-state="premiumStore.state"
                :free-pack-state="freePackState"
                :available-free-packs="availableFreePacks"
                :end-timestamp="hardCurrencyPremiumStore.endSaleDate
                    ? dayjs(hardCurrencyPremiumStore.endSaleDate).unix() * 1000
                    : undefined"
                @done="handleDoneClick"
                @buy="handleBuyHardCurrency"
                @close="premiumStore.toggleHardCurrency(false)"
            />

            <template v-if="route.path !== '/' && route.path !== '/account'">
                <h1
                    class="root__back"
                    @click="navigateTo('/')"
                >
                    <SvgIcon
                        class="root__back--icon"
                        name="arrow"
                    />
                    BACK
                </h1>
            </template>

            <div
                v-if="iframeData"
                class="root__button-frame"
            >
                <div
                    class="root__close-button"
                    @click="iframeData.close"
                >
                    <SvgIcon
                        name="cross"
                        class="root__close-button-icon"
                    />
                </div>
            </div>

            <slot />
        </div>
    </Login>
</template>

<style lang="scss" scoped>
@include tutorial;

.root {
    display: grid;

    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    width: 100%;
    height: 100dvh;

    overflow: hidden;

    background-image: url('/images/backgrounds/achievement-background.png');

    @supports (background-image: url('/images/backgrounds/achievement-background.webp')) {
        background-image: url('/images/backgrounds/achievement-background.webp');
    }

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &--bg-account {
        background-image: url('/images/backgrounds/account.png');
    }

    &__vignette {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 40%, rgb(0 0 0 / 80%) 100%);
        pointer-events: none;
        z-index: 1;

        &--blured {
            filter: blur(size(22px));
        }
    }

    &__video {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        filter: saturate(1) contrast(88%) brightness(1.1);

        &--blured {
            filter: blur(size(22px));
        }
    }

    &__header {
        grid-row: 1;
        grid-column: 1;

        align-self: start;
        justify-self: stretch;

        margin: size(21px) size(23px) 0;

        z-index: 10;
    }

    &__back {
        cursor: pointer;

        grid-row: 1;
        grid-column: 1;

        align-self: start;
        justify-self: start;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: size(119px);
        margin-left: size(30px);

        z-index: 10;

        color: rgba($color-white, 0.77);
        font-family: $font-family-base;
        font-size: size(18px);
        font-style: normal;
        font-weight: 300;
        line-height: size(16.808px);
        letter-spacing: size(0.72px);

        &--icon {
            transform: scaleX(-1);

            margin-left: size(7px);
            margin-right: size(9px);

            width: size(13.5px);
            height: size(21px);

            color: rgba(255, 255, 255, 0.77);
        }
    }

    &__button-frame {
        position: absolute;

        z-index: 39;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 150px);
        height: 90vh;
        max-width: 1243px;
        max-height: 700px;
    }

    &__close-button {
        cursor: pointer;

        position: absolute;
        top: 0;
        right: -60px;

        width: 50px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 7px;

        background: $color-primary;

        transition: opacity $base-transition, background $base-transition, color $base-transition, box-shadow $base-transition;

        &:hover {
            background: lighten($color-primary, 20%);
            box-shadow: 0 0 15px 0 rgba($color-white, 0.25);
        }
    }

    &__close-button-icon {
        width: 20px;
        height: 20px;

        color: rgba(0, 0, 0, 0.77);
    }
}
</style>
