<script setup lang="ts">
defineProps<{
    avatar: string;
    level: number;
    haveAirDropChance: boolean;
}>();

const emit = defineEmits(['click']);
</script>

<template>
    <div
        class="profile"
        @click="emit('click')"
    >
        <SvgIcon
            class="profile__bg"
            :class="[{ 'profile__bg--secondary': haveAirDropChance }]"
            :name="haveAirDropChance ? 'fire-secondary' : 'avatarBg'"
        />

        <NuxtImg
            preload
            class="profile__avatar"
            :src="avatar"
        />
    </div>
</template>

<style lang="scss" scoped>
.profile {
    cursor: pointer;

    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    position: relative;

    &__bg {
        grid-row: 1;
        grid-column: 1;

        width: size(52px);
        height: size(50px);

        color: $color-black;

        &--secondary {
            width: size(57px);
            height: size(74px);

            position: absolute;
            top: size(-24px);
            left: size(-4px);

            color: $color-primary;
        }
    }

    &__avatar {
        grid-row: 1;
        grid-column: 1;

        align-self: start;
        justify-self: center;

        margin-top: size(5px);

        width: size(42.438px);
        height: size(41px);

        border-radius: 100%;

        border: size(0.73px) solid rgba(255, 255, 255, 0.44);

        z-index: 2;
    }
}
</style>
