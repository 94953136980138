import { BIT_BOT_STATES, VIDEO_ANIMATIONS } from '~/constants/videoAnimationNames';
import eventService from '~/services/eventService';
import  { VideoAnimationConfig } from '~/types/videoAnimation';

export const useBitBotAnimationConfig = (): VideoAnimationConfig => ({
    data: [
        {
            stateName: BIT_BOT_STATES.Idle,
            allowedNewStates: [BIT_BOT_STATES.leftArmUp, BIT_BOT_STATES.approveAnim],
            startMs: 2500,
            endMs: 11100,
            loop: true,
        },
        {
            stateName: BIT_BOT_STATES.leftArmUp,
            startMs: 0,
            endMs: 1000,
            waitForNextState: true,
            onStart() {
                eventService.videoAnimation.emit('changeBlockState', true);
            },
            onComplete(_: string, nextAnim?: string) {
                if (nextAnim !== BIT_BOT_STATES.approveAnim) {
                    eventService.videoAnimation.emit('addToQueue', [
                        VIDEO_ANIMATIONS.BitBot, BIT_BOT_STATES.leftArmDown,
                    ]);
                }
            },
        },
        {
            stateName: BIT_BOT_STATES.leftArmDown,
            startMs: 1300,
            endMs: 2100,
            onComplete() {
                eventService.videoAnimation.emit('changeBlockState', false);
            },
        },
        {
            stateName: BIT_BOT_STATES.leftArmDownForApprove,
            startMs: 1300,
            endMs: 2100,
            speed: 2,
            onComplete() {
                eventService.videoAnimation.emit('changeBlockState', false);
            },
        },
        {
            stateName: BIT_BOT_STATES.leftArmUpForApprove,
            startMs: 0,
            endMs: 1000,
        },
        {
            stateName: BIT_BOT_STATES.approveAnim,
            startMs: 11600,
            endMs: 14000,
            onStart(state: string) {
                eventService.videoAnimation.emit('changeBlockState', true);

                if (state === BIT_BOT_STATES.leftArmDown || state === BIT_BOT_STATES.leftArmDownForApprove) {
                    eventService.videoAnimation.emit('addToQueue', [
                        VIDEO_ANIMATIONS.BitBot, BIT_BOT_STATES.leftArmUpForApprove,
                    ]);
                }
            },
            onComplete() {
                eventService.videoAnimation.emit('addToQueue', [
                    VIDEO_ANIMATIONS.BitBot, BIT_BOT_STATES.leftArmDownForApprove,
                ]);
            },
        },
    ],
    defaultStateName: BIT_BOT_STATES.Idle,
});
