<script lang="ts" setup>
import { authService, AuthType } from '~/services/authService';
import { SdkLoginType } from '~/services/authService/sdkProvider';

const props = defineProps<{
    initReferrerCode?: string;
    resendTimestamp?: number;
}>();

const emit = defineEmits<{
    switch: [];
    startTimer: [];
}>();

const referrerCode = ref<string | undefined>(props.initReferrerCode);
const forceShowRefCode = ref(false);
const hasReferrerCode = computed(() => !!props.initReferrerCode || forceShowRefCode.value);

const showEmailConfirm = ref(false);
const emailCode = ref('');

const showRedirectConfirm = ref(false);
const redirectType = ref<'wallet' | 'telegram'>('wallet');

const email = ref<string | undefined>(undefined);
const isEmail = ref(false);

const sendCode = () => {
    if (!email.value) {
        return;
    }

    authService.authSdkProvider?.sendEmailOtp(
        email.value,
        referrerCode.value,
    );

    emit('startTimer');
};

const emailLogin = async (email: string, code: string) => {
    await authService.login({
        type: AuthType.AuthSdk,
        loginType: SdkLoginType.Email,
        email,
        code,
    });

    navigateTo('/');

    isEmail.value = false;
};

watch(() => props.initReferrerCode, () => {
    referrerCode.value = props.initReferrerCode;
});
</script>

<template>
    <div class="other-login">
        <SvgIcon
            class="other-login__logo"
            name="logo"
        />

        <!-- <h1
            v-if="!isEmail"
            class="other-login__text"
        >
            WELCOME TO 77-BIT, THE CUTTING-EDGE<br>
            CYBERPUNK MMORPG ON YOUR BROWSER
        </h1> -->

        <template v-if="isEmail && !!email">
            <LoginConfirm
                class="other-login__confirm"
                :show="showEmailConfirm"
                @continue="emailLogin(email, emailCode); showEmailConfirm = false"
                @switch="showEmailConfirm = false"
            >
                <LoginOtherEmail
                    class="other-login__email"
                    :email="email"
                    :resend-timestamp="props.resendTimestamp"
                    @continue="emailCode = $event; showEmailConfirm = true"
                    @resend="sendCode"
                    @switch="isEmail = false"
                />
            </LoginConfirm>
        </template>
        <template v-else>
            <LoginConfirm
                class="other-login__confirm"
                :show="showRedirectConfirm"
                @continue="authService.login({
                    type: AuthType.AuthSdk,
                    loginType: redirectType === 'wallet' ? SdkLoginType.Wallet : SdkLoginType.Telegram,
                    refCode: referrerCode
                }); navigateTo('/'); showRedirectConfirm = false"
                @switch="showRedirectConfirm = false"
            >
                <div class="other-login__button-container">
                    <template v-if="hasReferrerCode">
                        <LoginRefCode
                            v-model="referrerCode"
                            class="other-login__ref-code"
                        />
                    </template>

                    <LoginOtherInput
                        v-model="email"
                        placeholder="ENTER YOUR EMAIL"
                        class="other-login__input"
                        @submit="isEmail = true; sendCode()"
                    />

                    <div class="other-login__redirect-buttons">
                        <LoginOtherButton
                            class="other-login__button"
                            icon-name="telegram"
                            text="telegram"
                            @click="redirectType = 'telegram'; showRedirectConfirm = true"
                        />

                        <LoginOtherButton
                            class="other-login__button"
                            icon-name="wallet"
                            text="wallet"
                            @click="redirectType = 'wallet'; showRedirectConfirm = true"
                        />
                    </div>
                </div>

                <h1
                    class="other-login__other-link"
                    @click="emit('switch')"
                >
                    BACK TO X CONNECT
                </h1>

                <h1
                    v-if="!forceShowRefCode"
                    class="other-login__invite-link"
                    @click="forceShowRefCode = true"
                >
                    HAVE AN INVITE CODE?
                </h1>
            </LoginConfirm>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.other-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo {
        width: size(248px);
        flex: 0 0 size(49.6px);
        color: $color-primary;
    }

    &__confirm {
        margin-top: size(46.4px);
    }

    // &__text {
    //     margin-top: size(28.4px);

    //     color: rgba($color-white, 0.77);
    //     text-align: center;
    //     font-family: $font-family-base;
    //     font-size: size(16px);
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: normal;
    //     letter-spacing: size(1.76px);
    // }

    &__email {
        margin-top: size(46.4px);

        width: size(403px);
    }

    &__button-container {
        margin-top: size(41px);

        width: size(403px);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__ref-code {
        margin-bottom: size(15px);

        width: 100%;
    }

    &__input {
        margin-bottom: size(15px);

        width: 100%;

        flex: 0 0 size(59.87px);
    }

    &__redirect-buttons {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;

        gap: size(13px);
    }

    &__button {
        flex: 1;
        height: size(60px);
    }

    &__other-link {
        margin-top: size(28px);
        cursor: pointer;

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);
        text-decoration-line: underline;
    }

    &__invite-link {
        margin-top: size(18px);
        cursor: pointer;

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);
        text-decoration-line: underline;
    }
}
</style>
