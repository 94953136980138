<script lang="ts" setup>
defineProps<{
    iconName: string;
    text: string;
}>();

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();
</script>

<template>
    <div
        class="button"
        @click="emit('click', $event)"
    >
        <SvgIcon
            class="button__icon"
            :class="`button__icon--${iconName}`"
            :name="iconName"
        />

        <h1 class="button__text">
            {{ text }}
        </h1>
    </div>
</template>

<style lang="scss" scoped>
.button {
    cursor: pointer;

    border-radius: size(7px);
    border: size(1px) solid rgba($color-white, 0.22);
    background: rgba($color-white, 0.04);

    transition: background $base-transition, border-color $base-transition;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background: rgba($color-white, 0.11);
        border-color: $color-primary;
    }

    &:active {
        background: rgba($color-white, 0.08);
        border-color: darken($color-primary, 5%);
    }

    &__icon {
        color: rgba(255, 255, 255, 0.77);

        transition: color $base-transition;

        &--telegram {
            width: size(22px);
            height: size(18px);

            margin-right: size(12px);
        }

        &--wallet {
            width: size(22px);
            height: size(17px);

            margin-right: size(12px);
        }
    }

    &__text {
        color: rgba($color-white, 0.77);
        font-family: $font-family-base;
        font-size: size(18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.98px);

        text-transform: uppercase;
    }

    &:hover &__icon {
        color: $color-primary;
    }

    &:active &__icon {
        color: darken($color-primary, 5%);
    }

    &:hover &__text {
        color: $color-primary;
    }

    &:active &__text {
        color: darken($color-primary, 5%);
    }
}
</style>
