<script setup lang="ts">
defineProps<{
    dailyLevel: number;
    bits: number;
    bytes: number;
    haveAirDropChance: boolean;
    isOpenTutorial: boolean;
    multiplier: number;
    profile: {
        avatar: string;
        level: number;
    }
}>();

const emit = defineEmits([
    'addBits',
    'account',
    'openTutorial',
]);
</script>

<template>
    <div class="info">
        <div class="common-param">
            <SvgIcon
                class="common-param__icon"
                :class="{ 'common-param__icon--ready': true }"
                name="boost"
            />

            <h1 class="common-param__text">
                {{ round(multiplier, 2) }}x
            </h1>
        </div>

        <div class="common-param">
            <SvgIcon
                class="common-param__icon"
                :class="{ 'common-param__icon--ready': true }"
                name="fire"
            />

            <h1 class="common-param__text">
                {{ dailyLevel }}
            </h1>
        </div>

        <div class="info__currencies">
            <div class="info__group bits">
                <SvgIcon
                    class="bits__icon"
                    name="currency-bits"
                />

                <h1 class="bits__text">
                    {{ bits }}
                </h1>

                <SvgIcon
                    class="bits__plus"
                    name="plus"
                    @click="emit('addBits')"
                />
            </div>

            <div
                class="info__group points"
                :class="[{ 'points--active': !isOpenTutorial }]"
                @click="emit('openTutorial')"
            >
                <div class="points__left">
                    <SvgIcon
                        class="points__icon"
                        name="byte"
                    />

                    <h1 class="points__text">
                        {{ bytes }}
                    </h1>
                </div>
            </div>
        </div>

        <HeaderInfoProfile
            class="info__profile"
            :avatar="profile.avatar"
            :level="profile.level"
            :have-air-drop-chance="haveAirDropChance"
            @click="emit('account')"
        />
    </div>
</template>

<style lang="scss" scoped>
.info {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__currencies {
        display: flex;
        gap: size(11px);
        min-width: size(222px);
    }

    &__group {
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-radius: size(22px);
        background: rgba(0, 0, 0, 0.22);

        width: auto;
        height: size(39px);

        padding: 0 size(8px);
    }

    &__profile {
        margin-left: size(26px);
    }
}

.points {
    flex: 0 0 size(112px);
    cursor: pointer;

    &--active {
        background: rgba(255, 255, 255, 0.22);
    }

    &__left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__icon {
        width: size(22px);
        height: size(22px);
        color: $color-secondary;
    }

    &__text {
        margin-left: size(8px);
        margin-top: size(1px);

        width: size(52px);
        text-align: center;

        color: $color-white;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: size(12.139px);
        letter-spacing: size(1.12px);
    }
}

.bits {
    flex: 0 0 size(112px);

    padding: 0 size(9px) 0 size(12px);

    overflow: hidden;

    &__icon {
        width: size(19px);
        height: size(20px);

        color: $color-primary;

        filter: drop-shadow(0 0 size(5px) $color-primary);
    }

    &__text {
        margin-left: size(5px);

        color: $color-primary;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: size(20px);

        filter: drop-shadow(0 0 size(5px) $color-primary);
    }

    &__plus {
        cursor: pointer;

        width: size(22px);
        height: size(25px);

        color: $color-white;

        &:hover {
            color: lighten($color-white, 5%);
        }

        &:active {
            color: darken($color-white, 5%);
        }

        opacity: 0.66;
    }
}

.common-param {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: size(7px);

    margin-right: size(23px);

    position: relative;

    &__icon {
        width: size(29.761px);
        height: size(31.315px);

        color: rgba(255, 255, 255, 0.77);

        transition: filter 150ms ease;

        &--ready {
            color: $color-primary;
            filter: drop-shadow(0 0 size(11px) $color-primary);
        }
    }

    &__text {
        color: $color-primary;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 600;
        line-height: 93.377%;

        text-shadow: 0 0 size(9.816px) $color-primary;
    }
}
</style>
