<script lang="ts" setup>
defineProps<{
    show: boolean;
}>();

const emit = defineEmits<{
    continue: [];
    switch: [];
}>();
</script>

<template>
    <div
        v-if="show"
        class="confirm"
    >
        <div class="confirm__container">
            <h1 class="confirm__title">
                Important Notice
            </h1>

            <h1 class="confirm__desc">
                Signing in with a new login<br>
                option will create a new account.<br>
                If you already have one, use your<br>
                previous login and link the new<br>
                one in account settings.
            </h1>
        </div>

        <LoginConfirmButton
            class="confirm__button"
            :is-enabled="true"
            @click="emit('continue')"
        >
            CONTINUE
        </LoginConfirmButton>

        <h1
            class="confirm__other-link"
            @click="emit('switch')"
        >
            BACK
        </h1>
    </div>
    <slot v-else />
</template>

<style lang="scss" scoped>
.confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border: solid size(2px) rgba($color-white, 0.22);
        border-radius: size(10px);

        padding: size(36px) size(32px);

        width: size(467px);
    }

    &__title {
        color: rgba($color-white, 0.77);
        font-family: $font-family-base;
        font-size: size(24px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: size(2.64px);

        text-transform: uppercase;
    }

    &__desc {
        margin-top: size(10px);

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);

        text-transform: uppercase;
    }

    &__button {
        margin-top: size(25px);

        width: size(467px);
        height: size(68px);
    }

    &__other-link {
        margin-top: size(25px);
        cursor: pointer;

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);
        text-decoration-line: underline;
    }
}
</style>
