<script setup lang="ts">
withDefaults(defineProps<{
    isEnabled?: boolean;
    cornerRadius?: number;
    cornerSize?: number;
}>(), {
    isEnabled: true,
    cornerRadius: 6,
    cornerSize: 20,
});

const emit = defineEmits<{
    click: [event: MouseEvent];
}>();
</script>

<template>
    <CommonButton
        class="button"
        :corners="{
            tl: { type: 'round', size: cornerRadius },
            tr: { type: 'round', size: cornerRadius },
            br: { type: 'angle', width: cornerSize, height: cornerSize },
            bl: { type: 'round', size: cornerRadius },
        }"
        :border="{ type: 'solid', width: 2 }"
        :is-enabled="isEnabled"

        @click="emit('click', $event)"
    >
        <slot name="icon" />

        <h1 class="button__text">
            <slot />
        </h1>
    </CommonButton>
</template>

<style scoped lang="scss">
.button {
    // TODO: extract colors
    --background-color: rgba(17, 17, 17, 0.88);
    --hover-background-color: #{lighten(rgba(17, 17, 17, 0.88), 5%)};
    --active-background-color: #{darken(rgba(17, 17, 17, 0.88), 5%)};

    --border-color: #{$color-primary};
    --hover-border-color: #{lighten($color-primary, 5%)};
    --active-border-color: #{darken($color-primary, 5%)};

    filter: drop-shadow(0 0 size(6px) $color-primary);
    transition: filter $base-transition;

    &__text {
        font-family: #{var(--font-family, $font-family-base)};
        font-size: size(var(--font-size-raw, 18px));
        font-style: normal;
        font-weight: var(--font-weight, 400);
        line-height: normal;
        letter-spacing:  var(--letter-spacing, size(1.26px));

        color: $color-primary;
        transition: color $base-transition;
    }

    &:hover &__text {
        color: lighten($color-primary, 5%);
    }

    &:active &__text {
        color: darken($color-primary, 5%);
    }
}
</style>
