<script lang="ts" setup>
const props = defineProps<{
    email: string;
    referrerCode?: string;
    resendTimestamp?: number;
}>();

const emit = defineEmits<{
    continue: [code: string];
    resend: [];
    switch: [];
}>();

const code = ref('');

const currentTimestamp = useTimestamp({ interval: 1000 });

const formatTime = (timestamp: number) => {
    const date = new Date(timestamp);
    return `${date.getSeconds()}s`;
};

const timeLeft = computed(() => {
    if (!props.resendTimestamp) {
        return undefined;
    }

    if (currentTimestamp.value > props.resendTimestamp) {
        return undefined;
    }

    return props.resendTimestamp - currentTimestamp.value;
});
</script>

<template>
    <div class="email">
        <div class="email__container">
            <h1 class="email__title">
                EMAIL AUTHENTICATION
            </h1>

            <h1 class="email__desc">
                TO CONTINUE, PLEASE ENTER THE<br>
                6-DIGIT VERIFICATION CODE SENT TO<br>
                YOUR EMAIL: {{ email }}
            </h1>

            <LoginOtherEmailInput
                v-model="code"
                class="email__input"
            />

            <h1 class="email__resend">
                DIDN’T RECIEVE A CODE? <span
                    class="email__resend--h"
                    :class="{ 'email__resend--h2': timeLeft }"
                    @click="timeLeft === undefined && emit('resend')"
                >RESEND {{ timeLeft ? `(${formatTime(timeLeft)})` : '' }}</span>
            </h1>
        </div>

        <LoginOtherEmailButton
            class="email__button"
            :is-enabled="code?.length === 6"
            @click="emit('continue', code)"
        >
            CONTINUE
        </LoginOtherEmailButton>

        <h1
            class="email__other-link"
            @click="emit('switch')"
        >
            BACK
        </h1>
    </div>
</template>

<style lang="scss" scoped>
.email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        border: solid size(2px) rgba($color-white, 0.22);
        border-radius: size(10px);

        padding: size(36px) size(32px);

        width: size(467px);
    }

    &__title {
        color: rgba($color-white, 0.77);
        font-family: $font-family-base;
        font-size: size(24px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: size(2.64px);

        text-transform: uppercase;
    }

    &__desc {
        margin-top: size(10px);

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);

        text-transform: uppercase;
    }

    &__input {
        margin-top: size(27px);

        width: size(401.5px);
    }

    &__resend {
        margin-top: size(20px);

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);

        text-transform: uppercase;

        &--h {
            cursor: pointer;
            color: $color-primary;
            text-decoration-line: underline;
        }

        &--h2 {
            cursor: unset;
            color: rgba($color-white, 0.44);
            text-decoration-line: unset;
        }
    }

    &__button {
        margin-top: size(25px);

        width: size(467px);
        height: size(68px);
    }

    &__other-link {
        margin-top: size(25px);
        cursor: pointer;

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);
        text-decoration-line: underline;
    }
}
</style>
