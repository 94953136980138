<script lang="ts" setup>
const value = defineModel<string | undefined>();
const count = 6;

const inputs = ref<HTMLInputElement[]>([]);
const values = ref<string[]>(Array(count).fill(''));
const focusedIndex = ref<number | undefined>(undefined);

watch(values, () => {
    const code = values.value.join('');
    value.value = code;
}, { deep: true });

const focusInput = (index: number) => {
    inputs.value[index]?.focus();

    if (inputs.value[index]?.value.length > 0) {
        inputs.value[index]?.select();
    }
};

const onInput = (index: number, event: Event) => {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value.length === 1) {
        if (index < count - 1) {
            focusInput(index + 1);
        }
    } else if (value.length === 0) {
        for (let i = index - 1; i >= 0; i--) {
            if (values.value[i].length > 0 || i === 0) {
                focusInput(i);
                return;
            }
        }
    }
};

const onBackSpace = (index: number) => {
    if (values.value[index].length > 0) return;

    for (let i = index - 1; i >= 0; i--) {
        if (values.value[i].length > 0 || i === 0) {
            focusInput(i);
            return;
        }
    }
};

const onPaste = (event: ClipboardEvent) => {
    event.preventDefault();

    const clipboardData = event.clipboardData;
    if (!clipboardData) {
        return;
    }

    const pastedData = clipboardData.getData('text');
    if (pastedData.length !== count) {
        return;
    }

    for (let i = 0; i < count; i++) {
        values.value[i] = pastedData[i];
    }
};

onMounted(() => {
    focusInput(0);
});
</script>

<template>
    <div class="input">
        <template
            v-for="i in count"
            :key="i"
        >
            <div class="input__container">
                <input
                    ref="inputs"
                    v-model="values[i - 1]"
                    class="input__letter"
                    :maxlength="1"
                    @input="onInput(i - 1, $event)"
                    @keydown.backspace="onBackSpace(i - 1)"
                    @focus="focusedIndex = i - 1"
                    @blur="focusedIndex = undefined"
                    @paste="onPaste"
                >

                <template v-if="focusedIndex === i - 1">
                    <div class="input__caret" />
                </template>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__container {
        width: size(58px);
        height: size(86.121px);

        position: relative;
    }

    &__letter {
        width: 100%;
        height: 100%;

        padding: size(21px) size(0px);

        background: rgba($color-white, 0.04);
        border: solid size(1px) rgba($color-white, 0.33);
        border-radius: size(4.6px);

        transition: background $base-transition;

        color: rgba($color-white, 0.77);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(33px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(3.63px);

        outline-color: transparent;
        outline-style: none;
        caret-color: transparent;
    }

    &__caret {
        position: absolute;
        bottom: size(21px);
        left: calc(50% - size(13.5px));

        width: size(27px);
        height: size(2px);

        background: rgba(255, 255, 255, 0.77);
    }
}
</style>
