<script setup lang="ts">
import { CardInfo } from '~/types/hardCurrencyPurchase';
import ScrollPan from '~/components/Common/ScrollPan.vue';

const props = defineProps<{
    data: (CardInfo & { endDateSale?: string; type?: 'daily' | 'flash' | 'default'; endTimestamp?: number })[];
    currentTimestamp: number;
    availableFreePacks: string[];
}>();

defineEmits<{
    (e: 'select', id: string): void
}>();

const isLargestScrollPan = computed(() => props.data.length > 6);

const isHaveDiscount = computed(() => props.data.some((item) => item.type && item.type !== 'default'));
</script>

<template>
    <ScrollPan
        class="scroll"
        :class="[
            { 'scroll--large': isLargestScrollPan || isHaveDiscount },
            { 'scroll--secondary': isHaveDiscount },
        ]"
        :with-fade="false"
    >
        <div class="items">
            <HardCurrencyPurchaseCard
                v-for="item in data"
                :key="item.id"
                :="item"
                :current-timestamp="currentTimestamp"
                :is-available="!item.freePack || availableFreePacks.includes(item.id)"
                @select="$emit('select', item.id)"
            />
        </div>
    </ScrollPan>
</template>

<style lang="scss" scoped>
.items {
    display: flex;
    flex-wrap: wrap;
    padding-left: size(14px);
    padding-right: size(10px);
    gap: size(11px);
}

.scroll {
    padding: 0 !important;
    background: none !important;
    height: size(613px) !important;

    :deep(.scroll-pan__bar_vertical) {
        right: size(8px);
    }

    &--secondary {
        :deep(.scroll-pan__panel) {
            height: -webkit-fill-available;
            padding-top: size(40px);
        }
    }

    &--large {
        height: size(650px) !important;
    }
}
</style>
