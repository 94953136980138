<script setup lang="ts">
import { CardInfo } from '~/types/hardCurrencyPurchase';

type Props = CardInfo & {
    buttonType?: 'primary' | 'secondary',
    readonly?: boolean,
    highlighted?: boolean,
    type?: 'daily' | 'flash' | 'default',
    endTimestamp?: number;
    currentTimestamp: number;
    isAvailable: boolean;
};

const props = withDefaults(defineProps<Props>(), {
    buttonType: 'primary',
    readonly: false,
    type: 'default',
    endTimestamp: undefined,
});

defineEmits<{
    (e: 'select', id: string): void
}>();

const totalAmount = computed(() => props.bonus ? props.amount + props.bonus : props.amount);
</script>

<template>
    <div
        class="card"
        :class="[
            { 'card--highlighted': flashSaleSetting?.enabled || highlighted },
            `card--${type}`
        ]"
    >
        <CommonClipContainer
            v-if="endTimestamp"
            class="card__timer"
            :corners="{
                tl: { type: 'round', size: 6 },
                tr: { type: 'round', size: 6 },
                br: { type: 'angle', size: 16 },
                bl: { type: 'round', size: 6 },
            }"
        >
            <CommonClipContainer
                class="card__timer-badge"
                :class="[
                    `card__timer-badge--${type}`
                ]"
                :corners="{
                    tl: { type: 'round', size: 6 },
                    tr: { type: 'round', size: 6 },
                    br: { type: 'angle', size: 16 },
                    bl: { type: 'round', size: 6 },
                }"
            >
                <span v-if="type === 'daily'">
                    DAILY PACK
                </span>
                <span v-if="type === 'flash'">
                    FLASH SALE
                </span>
            </CommonClipContainer>

            <span>
                {{ formatTimeLeft(endTimestamp - currentTimestamp) }}
            </span>
        </CommonClipContainer>
        <div class="card__inner">
            <NuxtImg
                preload
                class="card__item-icon"
                :class="[
                    { 'card__item-icon--custom': iconParams.iconSize },
                ]"
                :style="iconParams.iconSize ? {
                    '--w': `${iconParams.iconSize.w}px`,
                    '--h': `${iconParams.iconSize.h}px`,
                    '--mt': `${iconParams.iconSize.mt || 0}px`,
                } : undefined"
                :src="iconParams.name"
            />
            <div
                class="card__amount"
                :class="[
                    { 'card__amount--readonly': readonly },
                    { 'card__amount--without-bonus': !bonus && readonly },
                ]"
            >
                <BitPurpleIcon />
                <span>
                    {{ totalAmount }}
                </span>
            </div>

            <div
                v-if="bonus"
                class="card__bonus"
                :class="[{ 'card__bonus--readonly': readonly }]"
            >
                <span>
                    {{ amount }} +
                    <span
                        :class="[
                            { 'card__bonus--color-primary': !highlighted },
                            { 'card__bonus--highlighted': highlighted },
                        ]"
                    >
                        {{ bonus }} BONUS
                    </span>
                </span>
            </div>

            <CommonButtonCorneredRoundedGray
                v-if="!readonly"
                class="card__buy-btn"
                :class="[
                    `card__buy-btn--${buttonType}`,
                    { 'card__buy-btn--highlighted': flashSaleSetting?.enabled }
                ]"
                :is-enabled="isAvailable"
                @click="$emit('select', id)"
            >
                <div class="card__buy-btn-content">
                    <span
                        v-if="oldPrice && price !== '0'"
                        class="card__old-price"
                    >
                        {{ oldPrice }} {{ currencyName }}
                    </span>
                    <span
                        class="card__main-price"
                        :class="[`card__main-price--${buttonType}`]"
                    >
                        {{ isAvailable ? (price === '0' ? 'FREE' : `${price} ${currencyName}`) : 'SOLD OUT' }}
                    </span>
                </div>
            </CommonButtonCorneredRoundedGray>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.card {
    width: size(309px);
    height: size(296px);

    border-radius: size(9.4px);
    border: size(1.35px) solid rgba(255, 255, 255, 0.22);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.44) 100%);

    position: relative;

    &--flash {
        border-radius: size(9.4px);
        border: size(1.35px) solid #FF1879 !important;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.44) 100%);
        box-shadow: 0 0 size(14.4px) 0 rgba(255, 255, 255, 0.33);
    }

    &__timer {
        --background-color: #222;

        filter: drop-shadow(0 0 size(7px) rgba(255, 255, 255, 0.33));

        width: size(272px);
        height: size(36px);
        position: absolute;
        top: size(-25px);
        left: size(-10px);

        span {
            color: #FF1879;
            text-align: center;
            font-family: $font-family-base;
            font-size: size(14px);
            font-style: normal;
            font-weight: 600;
            line-height: 121%;
            letter-spacing: size(1.54px);

            position: absolute;
            right: size(17px);
            top: size(11px);
        }

        &-badge {
            --background-color: #FF1879;
            width: size(128px);
            height: size(29px);
            margin: size(4px) size(4px) size(4px) size(3px);

            &--daily {
                --background-color: #FFAC02FF;
            }

            span {
                color: #211F22;
                font-family: $font-family-base;
                font-size: size(14px);
                font-style: normal;
                font-weight: 600;
                line-height: 121%;
                letter-spacing: size(1.54px);

                width: max-content;
                display: flex;
                position-area: center;
                margin-left: size(10px);
                margin-bottom: size(6px);
            }
        }
    }

    &--highlighted {
        border: size(1.35px) solid #F0B7FF;
        box-shadow: 0 0 size(15px) 0 rgba(255, 255, 255, 0.33);
    }

    &__inner {
        padding: 0 size(10px) size(10px) size(10px);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__item-icon {
        width: size(177px);
        height: size(160px);
        filter: drop-shadow(0px 0px size(22px) rgba(255, 246, 246, 0.77));

        &--custom {
            width: size(var(--w));
            height: size(var(--h));
            margin-top: size(var(--mt));
        }
    }

    &__amount {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: size(8.5px);
        margin-top: size(15px);

        svg {
            width: size(35px);
            height: size(19px);
            filter: drop-shadow(0px 0px size(9.95px) #FFF);
        }

        span {
            color: #F0B7FF;
            font-family: $font-family-base;
            font-size: size(22px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: size(2.42px);
            text-shadow: 0 0 size(12.3px) #FFF;
        }

        &--readonly {
            margin-top: auto;
        }

        &--without-bonus {
            margin-bottom: size(38px);
        }
    }

    &__bonus {
        color: rgba(255, 255, 255, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(14px);
        font-style: normal;
        font-weight: 400;
        line-height: 93.377%;
        letter-spacing: size(0.98px);

        margin-top: size(3px);

        &--highlighted {
            color: #F0B7FF;
        }

        &--color-primary {
            color: $color-primary;
        }

        &--highlighted-secondary {
            color: #F0B7FF;
        }

        &--readonly {
            margin-bottom: size(25px);
        }
    }

    &__buy-btn {
        width: size(288px);
        height: size(50px);

        position: absolute;

        left: size(11px);
        bottom: size(8px);

        --background-color: rgba(0, 0, 0, 0.11);

        &--highlighted {
            --border-color: rgba(255, 255, 255, 0.77);
        }

        &-content {
            display: flex;
            gap: size(12px);
            place-items: center;
        }

        &--primary {
            --border-color: #FFAC02;
        }
    }

    &__main-price {
        color: $color-primary;
        text-align: center;
        text-shadow: 0 0 size(14.4px) rgba(255, 255, 255, 0.33);
        font-family: $font-family-base;
        font-size: size(18px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.98px);
        text-transform: uppercase;

        &--secondary {
            color: rgba(255, 255, 255, 0.77);
        }
    }

    &__old-price {
        color: rgba(255, 255, 255, 0.33);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(14px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.54px);
        text-decoration-line: line-through;
        text-transform: uppercase;
    }
}
</style>
