<script setup lang="ts">
const emit = defineEmits(['close']);
</script>

<template>
    <div class="footer">
        <div class="footer__divider" />

        <div class="footer__inner">
            <CommonText
                class="footer__text"
                :text="[
                    {
                        smartString: getSmartString('[BITS] ARE THE HARD CURRENCY OF 77BIT AND'),
                        highlightStyle: { color: 'rgba(255, 255, 255, 0.77)' },
                    },
                    'CAN BE USED TO PURCHASE ITEMS IN THE GAME.',
                ]"
            />
            <div
                class="footer__text"
            >
                <span>BY SELECTING A BITPACK, YOU AGREE TO OUR</span>
                <div>
                    <a>TERMS OF SERVICE</a> AND <a @click="emit('close'); navigateTo('/refund')">
                        REFUND POLICY
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.footer {
    height: size(80x);

    margin-top: size(15px);

    &__divider {
        height: size(1px);
        background: rgba(255, 255, 255, 0.22);
        margin-left: size(14px);
        margin-right: size(14px);
    }

    &__inner {
        display: flex;
        gap: size(52px);
        padding: size(23px) size(59px) size(19px) size(20px);
    }

    &__text {
        color: rgba(255, 255, 255, 0.44);
        font-family: $font-family-base;
        font-size: size(14px);
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        letter-spacing: size(1.54px);

        a {
            cursor: pointer;

            color: rgba(255, 255, 255, 0.77);
            text-decoration-line: underline;
        }
    }
}
</style>
