<script setup lang="ts">
const emit = defineEmits([
    'home', 'play',
]);
</script>

<template>
    <div class="buttons">
        <CommonButtonHome
            class="buttons__home"
            @click="emit('home')"
        />

        <CommonButtonCorneredRoundedInverted
            class="buttons__play"
            @click="emit('play')"
        >
            PLAY FREE
        </CommonButtonCorneredRoundedInverted>
    </div>
</template>

<style lang="scss" scoped>
.buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    column-gap: size(15px);

    &__home {
        flex: 0 0 size(154px);
        height: size(46px);
    }

    &__play {
        flex: 0 0 size(181px);
        height: size(46px);
    }
}
</style>
