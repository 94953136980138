<script lang="ts" setup>
import { authService, AuthType } from '~/services/authService';
import { SdkLoginType } from '~/services/authService/sdkProvider';

const props = defineProps<{
    initReferrerCode?: string;
}>();

const emit = defineEmits(['switch']);

const referrerCode = ref<string | undefined>(props.initReferrerCode);
const forceShowRefCode = ref(false);
const hasReferrerCode = computed(() => !!props.initReferrerCode || forceShowRefCode.value);
const showConfirm = ref(false);

watch(() => props.initReferrerCode, () => {
    referrerCode.value = props.initReferrerCode;
});

</script>

<template>
    <div class="twitter-login">
        <SvgIcon
            class="twitter-login__logo"
            name="logo"
        />

        <LoginConfirm
            class="twitter-login__confirm"
            :show="showConfirm"
            @continue="authService.login({
                type: AuthType.AuthSdk,
                loginType: SdkLoginType.Twitter,
                refCode: referrerCode
            }); navigateTo('/'); showConfirm = false"
            @switch="showConfirm = false"
        >
            <!-- <h1 class="twitter-login__text">
            WELCOME TO 77-BIT, THE CUTTING-EDGE<br>
            CYBERPUNK MMORPG ON YOUR BROWSER
        </h1> -->

            <div class="twitter-login__button-container">
                <template v-if="hasReferrerCode">
                    <LoginRefCode
                        v-model="referrerCode"
                        class="twitter-login__ref-code"
                    />
                </template>

                <LoginTwitterButton
                    class="twitter-login__button"
                    @click="showConfirm = true"
                >
                    Connect
                </LoginTwitterButton>
            </div>

            <h1
                class="twitter-login__other-link"
                @click="emit('switch')"
            >
                OTHER LOGIN METHODS
            </h1>

            <h1
                v-if="!forceShowRefCode"
                class="twitter-login__invite-link"
                @click="forceShowRefCode = true"
            >
                HAVE AN INVITE CODE?
            </h1>
        </LoginConfirm>
    </div>
</template>

<style lang="scss" scoped>
.twitter-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo {
        width: size(248px);
        flex: 0 0 size(49.6px);
        color: $color-primary;
    }

    &__confirm {
        margin-top: size(46.4px);
    }

    // &__text {
    //     margin-top: size(28.4px);

    //     color: rgba($color-white, 0.77);
    //     text-align: center;
    //     font-family: $font-family-base;
    //     font-size: size(16px);
    //     font-style: normal;
    //     font-weight: 400;
    //     line-height: normal;
    //     letter-spacing: size(1.76px);
    // }

    &__button-container {
        margin-top: size(50px);

        width: size(378px);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__ref-code {
        margin-bottom: size(28px);

        width: 100%;
    }

    &__button {
        width: 100%;
        height: size(68px);
    }

    &__other-link {
        margin-top: size(25px);
        cursor: pointer;

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);
        text-decoration-line: underline;
    }

    &__invite-link {
        margin-top: size(18px);
        cursor: pointer;

        color: rgba($color-white, 0.44);
        text-align: center;
        font-family: $font-family-base;
        font-size: size(16px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: size(1.76px);
        text-decoration-line: underline;
    }
}
</style>
