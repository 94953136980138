<script setup lang="ts">
const tabs = [
    {
        tab: 'achivements',
        icon: 'drop',
        path: '/',
        enabled: true,
    },
    {
        tab: 'vault',
        icon: 'chest',
        enabled: false,
    },
    {
        tab: 'battlepass',
        icon: 'ticket',
        path: '/battle-pass',
        enabled: false,
    },
    {
        tab: 'shop',
        icon: 'cart',
        path: '/store',
        enabled: false,
    },
    {
        tab: 'help',
        icon: 'more',
        enabled: false,
    },
];

const route = useRoute();

const availableTabList = [
    '/',
    '/battle-pass',
    '/store',
];

const checkActiveTabPath = (path?: string) => {
    return path && availableTabList.includes(route.path) && path === route.path;
    // TODO: why use the switch case?
    // switch (path) {
    //     case '/':
    //         return route.path === '/';
    //     case '/battle-pass':
    //         return route.path !== '/';
    //     default:
    //         return false;
    // }
};
</script>

<template>
    <div class="tabs">
        <template
            v-for="tab in tabs"
            :key="tab.path"
        >
            <SvgIcon
                v-if="tab.enabled"
                class="tabs__icon"
                :class="[`tabs__icon--${tab.tab}`, { 'tabs__icon--active': checkActiveTabPath(tab.path) }]"
                :name="tab.icon"
                @click="tab.path && navigateTo(tab.path)"
            />
        </template>
    </div>
</template>

<style lang="scss" scoped>
.tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: size(55px);

    &__icon {
        cursor: pointer;
        color: #CFCDCD;

        transition: filter 150ms ease;

        &--achivements {
            width: size(28.6px);
            height: size(33px);
        }

        &--vault {
            width: size(32px);
            height: size(28.444px);
        }

        &--battlepass {
            width: size(37px);
            height: size(36px);
        }

        &--shop {
            width: size(30px);
            height: size(30px);
        }

        &--help {
            width: size(26px);
            height: size(26px);
        }

        &--active {
            color: $color-primary;
            filter: drop-shadow(0 0 size(11px) $color-primary);
        }
    }
}
</style>
