<script setup lang="ts">
import eventService from '~/services/eventService';
import { VideoAnimationConfig } from '~/types/videoAnimation';

const props = defineProps<{
    srcSet: string[];
    config: VideoAnimationConfig;
    name: string;
}>();

const video = ref<HTMLVideoElement | null>(null);
const currentState = ref<string>(props.config.defaultStateName);
const animationQueue = ref<string[]>([]);
const isInBlockChangeStates = ref<boolean>(false);
const lastAnimStartAt = ref<number>(0);

const currenStateData = computed(() => props.config.data.find(item => item.stateName === currentState.value));

const handleChangeState = async ([animName, newState, force]: [string, string, boolean?]) => {
    if (props.name !== animName ||
        !currenStateData.value ||
        !video.value ||
        (!force && isInBlockChangeStates.value) ||
        (currenStateData.value.allowedNewStates && !currenStateData.value.allowedNewStates?.includes(newState))
    ) {
        return;
    }

    if (video.value.currentTime <= currenStateData.value.endMs / 1000
        && currenStateData.value.waitForNextState
        && currenStateData.value.stateName !== props.config.defaultStateName
    ) {
        const outAnimation = props.config.data.find(item => item.stateName === currenStateData.value?.outStateName);

        if (outAnimation && !currenStateData.value.transitions?.includes(newState)) {
            video.value.currentTime = Math.abs(outAnimation.endMs - (Date.now() - lastAnimStartAt.value)) / 1000;
            currentState.value = outAnimation.stateName;
        } else if (currenStateData.value.transitions?.includes(newState)) {
            animationQueue.value.push(newState);
        }

        return;
    }

    await handleStatePlay(newState);
};

const handleUpdateVideoTime = (updatedTimeSec: number) => {
    if (!currenStateData.value || !video.value )
        return;

    const { startMs, endMs, stateName, loop } = currenStateData.value;
    const isDefaultState = stateName === props.config.defaultStateName;

    if (updatedTimeSec >= endMs / 1000 && !!loop) {
        video.value.currentTime = startMs / 1000;
        return;
    }

    if (updatedTimeSec >= endMs / 1000 && !loop && !isDefaultState && !video.value.paused) {
        handleEndState();
    }
};

const handleEndState = () => {
    if (!video.value || !currenStateData.value) {
        return;
    }

    const { waitForNextState, onComplete } = currenStateData.value;

    onComplete?.(currentState.value, animationQueue.value[0]);

    if (animationQueue.value.length) {
        const firstEl = animationQueue.value.shift();
        handleStatePlay(firstEl);
        return;
    }

    waitForNextState ? video.value.pause() : handleStatePlay(props.config.defaultStateName);

};

const handleStatePlay = async (stateToPlay?: string) => {
    if (!video.value || !stateToPlay)
        return;

    const { data } = props.config;

    const videoData = data.find(item => item.stateName === stateToPlay);

    if (!videoData) {
        console.error(`The default video state "${stateToPlay}", was not found!`);
        return;
    }
    videoData.onStart?.(currentState.value);

    const queue = videoData?.preStartQueue?.(currentState.value) || [];

    animationQueue.value.push(...queue);

    const firstEl = animationQueue.value.shift();
    const stateData = animationQueue.value.length > 0 ?
        data.find(item => item.stateName === firstEl)
        : videoData;

    if (stateData) {
        currentState.value = stateData.stateName;
        video.value.playbackRate = stateData.speed ?? 1;

        video.value.currentTime = stateData.startMs / 1000;

        await video.value.play();
        lastAnimStartAt.value = Date.now();
    }
};

const handleChangeBlockState = (value: boolean) => {
    isInBlockChangeStates.value = value;
};

const addToQueueHandler = ([animName, newState]: [string, string, boolean?]) => {
    if (props.name !== animName || animationQueue.value[0] === newState || currentState.value === newState) {
        return;
    }

    animationQueue.value.push(newState);

    if (currentState.value === props.config.defaultStateName) {
        const newAnim = animationQueue.value.shift();
        handleStatePlay(newAnim);
    }
};

onMounted(() => {
    if (video.value) {
        video.value.ontimeupdate = () => handleUpdateVideoTime(video.value?.currentTime ?? 0);
        video.value.onended = () => handleEndState();
    }
    eventService.videoAnimation.on('changeState', handleChangeState);
    eventService.videoAnimation.on('changeBlockState', handleChangeBlockState);
    eventService.videoAnimation.on('addToQueue', addToQueueHandler);
    handleStatePlay(props.config.defaultStateName);
});

onBeforeUnmount(() => {
    eventService.videoAnimation.off('changeState', handleChangeState);
    eventService.videoAnimation.off('changeBlockState', handleChangeBlockState);
});

</script>

<template>
    <video
        ref="video"
        preload="auto"
        disablePictureInPicture
        muted
    >
        <template
            v-for="src in srcSet"
            :key="src"
        >
            <source
                :src="src"
                :type="`video/${src.split('.').pop()}`"
            >
        </template>
    </video>
</template>
